<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0">
					<p class="text-h6 mb-4">Search</p>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
							<v-text-field dense clearable label="Name" v-model="filters.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" order="last">
							<v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
								<v-icon small left>mdi-magnify</v-icon> Search
							</v-btn>
							<v-btn small class="elevation-0" :ripple="false" @click='reset'>
								<v-icon small left>mdi-undo</v-icon> Reset
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:item='{ item, index }'>
						<tr>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.parent.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.code"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.icon"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.link"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.permission.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.is_group.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
								<v-menu bottom offset-y v-if="item.can_edit">
									<template v-slot:activator="{ on, attrs }">
										<v-btn small icon v-bind="attrs" v-on="on">
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item @click="openUpdateDialog(index, item)">
											<v-list-item-title>Edit Menu</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<update-dialog ref="updateDialog" :menus="menus" :permissions="permissions" @updated="updateItems"></update-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import MenuUpdateDialog from './MenuUpdateDialog.vue'

export default {
	name: 'MenuList',
	components: {
        messageNotifier: MessageNotifier,
		updateDialog: MenuUpdateDialog,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			filters: {
				name: ''
			},
			permissions: [],
			menus: [],
			items: [],
			headers: [],
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/menu/getMenus', this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.permissions = response.data.permissions
				this.menus = response.data.menus
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { name: '' }
		},
		openUpdateDialog: function(index, item) {
            this.selectedIndex = index
            var object = copyObject(item, ['can_edit'])
            object.is_group = item.is_group.text
            object.parent = item.parent.value
            object.permission = item.permission.value
            this.updateDialog.updateObject(object)
			this.updateDialog.open()
		},
		updateItems: function(item) {
            this.get()
            this.openNotifier(item.message)
		},
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        }
	}
}

</script>