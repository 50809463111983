<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-3">Update Menu</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Name*" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense readonly label="Is Group" v-model="object.is_group"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Code" :rules="rules.code" :error-messages="errors.code" v-model="object.code"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="object.is_group">
							<v-text-field dense label="Icon Name*" :rules="rules.icon" :error-messages="errors.icon" v-model="object.icon"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="!object.is_group">
							<v-text-field dense label="Link*" :rules="rules.link" :error-messages="errors.link" v-model="object.link"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="!object.is_group">
							<v-select dense label="Parent" :items="menus" :menu-props="{top: false, offsetY: true}" :error-messages="errors.parent" v-model="object.parent"></v-select>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="!object.is_group">
							<v-select dense label="Permission*" :items="permissions" :rules="rules.permission" :menu-props="{top: false, offsetY: true}" :error-messages="errors.permission" v-model="object.permission"></v-select>
						</v-col>

					</v-row>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'MenuUpdateDialog',
    props: {
        menus: {
            type: Array,
            required: false,
            default: ()=> []
        },
        permissions: {
            type: Array,
            required: false,
            default: ()=> []
        }
    },
	components: {

	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			url: '',
			object: {
                name: '',
				code: '',
				is_group: '',
				permission: '',
				parent: '',
				link: '',
				icon: ''
			},
			rules: {
				name: [
					(value) => !!value || 'Name is required',
					(value) => value.length >= 1 && value.length <= 100 || 'Name must be between 1 and 100 characters'
				],
				code: [
					(value) => !!value || 'Code is required',
					(value) => value.length >= 1 && value.length <= 100 || 'Code must be between 1 and 100 characters'
				],
				link: [
					(value) => !!value || 'Link is required',
				],
                permission: [
                    (value) => !!value || 'Permission is required',
                ],
                icon: [
                    (value) => !!value || 'Icon name is required',
                ],
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.resetValidation()
			this.object = { name: '', code: '', is_group: '', link: '', icon: '', permission: '', parent: '', }
			this.errors = {}
		},
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true
				var object = copyObject(this.object, ['id', 'is_group'])
				this.$store.dispatch('epanel/menu/updateMenu', { id: this.object.id, object: object }).then((response) => {
                    this.close()
                    this.$emit('updated', { object: response.data.object, message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		}
	}
}

</script>